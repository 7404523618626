import { Box, useToken } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { useInterpolatedColors } from "./use-interpolated-colors";

import type { RippleProps } from "./types";

const paths = [
  "M312.353 156.242c19.444 172.827 97.195 281.181 233.245 325.063 204.079 65.822 614.175 92.15 861.678 234.937 247.496 142.783 687.059 32.401 631.647-212.66C1983.512 258.52 1920.412-119.2 1103.201-119.2c-544.813 0-808.423 91.812-790.845 275.442h-.003z",
  "M344.019 146.086c9.114 163.568 76.877 266.118 203.28 307.649 189.61 62.296 576.32 87.214 802.891 222.35 226.564 135.135 648.511 30.667 609.238-201.267-39.273-231.933-78.693-589.418-852.125-589.418-515.626 0-770.05 86.893-763.282 260.686h-.002z",
  "M376.487 135.93c.226 154.31 58.904 251.055 176.027 290.235 175.687 58.77 539.234 82.278 746.062 209.765 206.822 127.485 610.233 28.93 585.055-189.875C1858.453 227.25 1839.563-110 1109.911-110 623.47-110 379-28.025 376.49 135.93h-.003z",
  "M409.69 125.774c-7.344 145.052 43.076 235.992 151.253 272.821 162.268 55.244 502.85 77.34 691.026 197.18 188.17 119.835 572.203 27.193 559.25-178.483-12.953-205.677-14.195-522.692-700.068-522.692-457.254 0-691.069 77.057-701.458 231.174h-.003z",
  "M443.57 115.618c-13.698 135.793 29.226 220.929 128.766 255.407 149.312 51.718 467.116 72.404 637.644 184.593 170.523 112.187 534.4 25.459 531.948-167.09-2.452-192.548 11.297-489.328-630.797-489.328-428.068 0-650.583 72.138-667.559 216.418h-.002z",
  "M478.08 105.463c-18.923 126.534 17.213 205.865 108.402 237.992 136.786 48.192 431.983 67.468 585.796 172.008 153.808 104.537 496.809 23.722 503.257-155.698 6.448-179.42 32.72-455.965-565.596-455.965-398.88 0-609.496 67.22-631.858 201.663h-.002z",
  "M513.176 95.307c-23.09 117.275 6.92 190.802 90.027 220.578 124.662 44.666 397.414 62.531 535.384 159.422 137.967 96.888 459.416 21.987 473.266-144.305 13.85-166.292 50.335-422.602-504.2-422.602-369.695 0-567.85 62.301-594.475 186.907h-.002z",
  "M548.827 85.151c-26.264 108.017-1.755 175.738 73.522 203.164 112.918 41.14 363.376 57.595 486.326 146.836 122.946 89.24 422.21 20.251 442.05-132.912C1570.568 149.075 1615.092-87 1104.336-87 763.825-87 578.66-29.617 548.828 85.151h-.002z",
  "M585.004 74.995c-28.493 98.759-8.894 160.675 58.793 185.75 101.533 37.613 329.84 52.658 438.55 134.25 108.707 81.59 385.18 18.515 409.674-121.52C1516.515 133.44 1567.015-82.4 1100.037-82.4c-311.322 0-482.996 52.464-515.031 157.395h-.002z",
  "M621.684 64.84c-29.82 89.5-14.566 145.611 45.76 168.336 90.492 34.086 296.786 47.72 392.001 121.663 95.212 73.942 348.32 16.78 376.187-110.127 27.867-126.907 82.37-322.512-340.828-322.512-282.135 0-439.839 47.545-473.119 142.64h-.001z",
  "M658.846 54.684c-30.279 80.24-18.824 130.548 34.362 150.922 79.78 30.56 264.194 42.784 346.632 109.078 82.434 66.292 311.622 15.043 341.63-98.735C1411.48 102.17 1468.09-73.2 1088.672-73.2c-252.948 0-396.22 42.627-429.823 127.884h-.002z",
  "M696.478 44.528c-29.897 70.982-21.714 115.485 24.545 133.508 69.39 27.034 232.052 37.848 302.407 96.492 70.353 58.643 275.083 13.308 306.04-87.343C1360.428 86.535 1417.306-68.6 1081.666-68.6c-223.763 0-352.156 37.708-385.187 113.128h-.001z",
];

const reversePaths = paths.slice(0).reverse();

export const Ripple = (props: RippleProps) => {
  const { start = "secondary.500", stop = "primary.500", ...rest } = props;

  const getColor = useInterpolatedColors(start, stop);
  const stopColor = useToken("colors", stop);
  const [ref, inView] = useInView();
  return (
    <Box pos="absolute" boxSize="auto" top={0} right={0} ref={ref} {...rest}>
      <AnimatePresence>
        {inView && (
          <Box as={motion.svg} width={1200} height={580} viewBox="0 0 1200 580">
            <motion.path
              d="M734.567 34.372c-28.692 61.724-23.266 100.422 16.275 116.094 59.313 23.508 200.347 32.911 259.299 83.906 58.95 50.994 238.697 11.572 269.438-75.95C1310.32 70.9 1365.669-64 1073.808-64c-194.576 0-307.654 32.79-339.24 98.372h-.001z"
              fill={stopColor}
              transition={{ delay: 0.05 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: "100%" }}
              initial={{ opacity: 0, x: "100%" }}
            />
            {paths.map((path, colorIdx) => {
              const animationIdx = reversePaths.indexOf(path);
              const colorPercentage = (1 / paths.length) * colorIdx;
              return (
                <motion.path
                  d={path}
                  key={`ripple${animationIdx}`}
                  animate={{ opacity: 1, x: 0 }}
                  fill={getColor(colorPercentage)}
                  exit={{ opacity: 0, x: "100%" }}
                  initial={{ opacity: 0, x: "100%" }}
                  transition={{ delay: 0.05 * (animationIdx + 0.05) }}
                  style={{ position: "relative", zIndex: animationIdx }}
                />
              );
            })}
          </Box>
        )}
      </AnimatePresence>
    </Box>
  );
};
